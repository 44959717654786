<template>
    <v-container>
        <v-row class="ma-0 pa-2">
            <span v-if="liga == 'https://backendmf.unocrm.mx/'">Comedores</span>
            <span v-else>Sucursales</span>
            <v-spacer/>
            <!-- Crear -->
            <v-dialog v-model="createDialog" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" style="font-size:12px; float:right;" height="24px" rounded outlined>Crear</v-btn>
                </template>
                <create @closeCreateDialogBranch="closeCreateDialogBranch"/>
            </v-dialog>
        </v-row>
        <div v-for="(b, index) in branches" :key="index" class="pb-2">
            <v-row class="ma-0">
                <strong>{{b.name}}</strong> 
                <v-spacer/>
                <v-icon @click="editItem(b)" x-small>mdi-pencil</v-icon>
            </v-row>
            <span style="font-size:12px;">
                <v-icon x-small>mdi-google-maps</v-icon>{{branches[index].address}}
            </span>
            <v-divider v-if="(index+1)<branches.length"></v-divider>
        </div>
        <!-- Editar -->
        <v-dialog v-model="editDialog" max-width="700px">
          <edit v-bind:branch="branch" @closeEditDialogBranch="closeEditDialogBranch"/>
        </v-dialog>
        <!-- confirmación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro que deseas borrar este contacto?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="deleteContact()">
                    Eliminar
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    Cancelar
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from "axios";
import Create from "../branches/create"
import Edit from "../branches/edit"
export default {
    components: {
        'create':Create,
        'edit':Edit,
    }, 
    data (){
        return{
            company:'',
            editDialog: false,
            createDialog: false,
            deleteId:'',
            sheet: false,
            snackbar: {
                show: false,
                message: null,
                color: null
            },
            branch:{},
            branches:[]
        }
    },
    computed:{
        liga(){
            return process.env.VUE_APP_BACKEND_ROUTE
        },
    },
    methods:{
        getDataFromApi(){
            axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v2/company_branches?filter[company_id]=' + this.$route.params.id).then(response=>{
                this.branches = response.data.data
            })
        },
        editItem(branch){
            this.branch = branch
            this.editDialog = true
        },
        closeEditDialogBranch: function(params) {
            this.editDialog = params;
        },
        closeCreateDialogBranch: function(params) {
            if(params!=undefined&&params.name!=''){
                this.branches.push(params)
            }
            this.createDialog = false;
        },
        deleteItem (id) {
            this.deleteId = id
            this.sheet = true
        },
        deleteContact(){
            axios.delete(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/company_branches/"+this.deleteId).then(response => {
                this.deleteId = ''
                this.sheet = false
                this.getDataFromApi()
            }).catch(error => {
                this.snackbar = {
                    message: error.response.data.message,
                    color: 'error',
                    show: true
                }
            });
        },
        cancel(){
            this.deleteId = ''
            this.sheet = false
        },
    },
    created(){
        this.getDataFromApi()
    }
}
</script>

<style>

</style>